
export interface Article {
  number: number
  title: string
  labels: string[]
  createdAt: string
}

export const Articles: Article[] = [
  {
    number: 68,
    title: 'Advanced Shell Scripting Techniques: Automating Complex Tasks with Bash',
    labels: ['news','draft'],
    createdAt: '2024-09-22T08:23:06Z',
  },
  {
    number: 67,
    title: 'Cash 哥的工程師必備',
    labels: ['reference','draft'],
    createdAt: '2024-09-21T15:03:52Z',
  },
  {
    number: 66,
    title: '8岁黑客是如何入侵Uber公司的？',
    labels: ['news','draft'],
    createdAt: '2024-09-21T14:59:59Z',
  },
  {
    number: 65,
    title: 'Guru Series',
    labels: ['reference','draft'],
    createdAt: '2024-09-21T14:58:59Z',
  },
  {
    number: 64,
    title: 'SQL vs NoSQL is the Wrong Distinction',
    labels: ['reference','draft'],
    createdAt: '2024-09-21T14:56:48Z',
  },
  {
    number: 63,
    title: 'benchmarks leaderboard ranking',
    labels: ['self','draft'],
    createdAt: '2024-09-21T14:53:08Z',
  },
  {
    number: 62,
    title: 'my-salary-progression-from-amazon-microsoft-google',
    labels: ['news','draft'],
    createdAt: '2024-09-21T14:47:42Z',
  },
  {
    number: 61,
    title: 'qrtt1 參加 WSA GAAS 計畫的一些學習心得分享',
    labels: ['reference','draft'],
    createdAt: '2024-09-21T14:46:59Z',
  },
  {
    number: 60,
    title: 'Honeypot',
    labels: ['news','draft'],
    createdAt: '2024-09-20T14:16:46Z',
  },
  {
    number: 59,
    title: 'EP27: Stack Overflow Architecture. Also...',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:16:19Z',
  },
  {
    number: 58,
    title: 'Flowchart of how slack decides to send a notification',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:15:46Z',
  },
  {
    number: 57,
    title: 'You’re Going To Have To Pay To Use Some Fancy Colors In Photoshop Now',
    labels: ['news','draft'],
    createdAt: '2024-09-20T14:15:16Z',
  },
  {
    number: 56,
    title: 'Google拒絕為歐洲電信業者負擔網路成本',
    labels: ['news','draft'],
    createdAt: '2024-09-20T14:14:26Z',
  },
  {
    number: 55,
    title: 'infracost',
    labels: ['news','draft'],
    createdAt: '2024-09-20T14:13:26Z',
  },
  {
    number: 54,
    title: 'true-apples-icloud',
    labels: ['meme','draft'],
    createdAt: '2024-09-20T14:10:29Z',
  },
  {
    number: 53,
    title: 'Hunt for your Python Job',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:09:48Z',
  },
  {
    number: 52,
    title: 'Richardson Maturity Model',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:09:04Z',
  },
  {
    number: 51,
    title: '硬碟裝滿資料會變重嗎？',
    labels: ['meme','draft'],
    createdAt: '2024-09-20T14:07:54Z',
  },
  {
    number: 50,
    title: '網路異常疑機房失火，老闆：「不是有防火牆？」',
    labels: ['meme','draft'],
    createdAt: '2024-09-20T14:07:20Z',
  },
  {
    number: 49,
    title: 'EP22: Latency numbers you should know. Also...',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:06:45Z',
  },
  {
    number: 48,
    title: 'mage-stacks-and-iphone-racks-building-an-internet-scale-meme-search-engine',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:05:59Z',
  },
  {
    number: 47,
    title: '15-fundamental-tips-on-rest-api-design',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:05:30Z',
  },
  {
    number: 46,
    title: '網頁大小 14KB 與 15KB 的速度差異',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:04:28Z',
  },
  {
    number: 45,
    title: 'nginx playground ',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:03:35Z',
  },
  {
    number: 44,
    title: 'Random Mosaic – Detecting unauthorized physical access with beans, lentils and colored rice',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T14:02:30Z',
  },
  {
    number: 43,
    title: 'The people making money from just surfing the internet',
    labels: ['news','draft'],
    createdAt: '2024-09-20T14:01:34Z',
  },
  {
    number: 42,
    title: 'Macbook touch bar for git in iTerm2',
    labels: ['reference','draft'],
    createdAt: '2024-09-20T13:56:36Z',
  },
  {
    number: 41,
    title: 'freepublicapis',
    labels: ['reference','draft'],
    createdAt: '2024-09-11T14:46:33Z',
  },
  {
    number: 40,
    title: 'Maybe I Should Make an API Monkey Someday',
    labels: ['self','draft'],
    createdAt: '2024-09-10T15:31:08Z',
  },
  {
    number: 39,
    title: '正確地使用 Header',
    labels: ['reference','draft'],
    createdAt: '2024-09-10T15:28:56Z',
  }
]
